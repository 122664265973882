import { IS_APP, IS_NODE, IS_NODE_BROWSERLESS } from "@/__main__/constants.mjs";
import getData from "@/__main__/get-data.mjs";
import blitzMessage, { EVENTS } from "@/__main__/ipc-core.mjs";
import LeagueAccountModel from "@/data-models/league-account.mjs";
import LoLLoading from "@/game-lol/models/lol-loading-lcu.mjs";
import { parseInProgressData } from "@/game-lol/utils/in-game-util.mjs";
import { getSummonerById } from "@/game-lol/utils/lol-client-api.mjs";
import { fixture as liveClientFixture } from "@/game-lol/utils/lol-live-client-data.mjs";
import * as API from "@/game-tft/api.mjs";
import fetchCompsData from "@/game-tft/fetch-tft-comps-data.mjs";
import { devError } from "@/util/dev.mjs";
import retry from "@/util/retry-promise.mjs";

const pathInProgress = ["volatile", "LoLLoadingState"];
const fetchOptions = { shouldFetchIfPathExists: true, skipSafetyCheck: true };

async function fetchLiveGame(region) {
  let inProgressData;

  try {
    if (IS_APP) {
      const progressData = await retry(
        () => blitzMessage(EVENTS.DB_READ, "LOL_INPROGRESS_DATA"),
        {
          maxRetries: 5,
          interval: 300,
        },
      )
        .then((data) => JSON.parse(data))
        .catch((error) => {
          throw new Error("Failed to fetch in progress data", error);
        });

      inProgressData = progressData;
      if (!inProgressData?.lcu?.gameData) {
        throw new Error("Failed to parse in progress data", inProgressData);
      }

      await getData(() => inProgressData, LoLLoading, pathInProgress, {
        ...fetchOptions,
        shouldSkipDBRead: true,
      });
    } else if ((!IS_APP && !IS_NODE_BROWSERLESS) || IS_NODE) {
      inProgressData = liveClientFixture();
    }

    if (!inProgressData) throw new Error("Could not find in progress data");

    const { teammates, enemyTeam } = parseInProgressData(inProgressData);

    const summonersPromises = [...teammates, ...enemyTeam].map(
      async (summoner) => {
        if (summoner.summonerId) {
          let gameName, tagLine;
          try {
            ({ gameName, tagLine } = await getSummonerById(
              summoner.summonerId,
            ));
          } catch (e) {
            devError("failed to get summoner by id", e);
            return null;
          }
          return getData(
            API.getPlayer(region, gameName, tagLine),
            LeagueAccountModel,
            ["tft", "accounts_v2", summoner.summonerId],
            fetchOptions,
          );
        }
        return null;
      },
    );

    return Promise.all(summonersPromises);
  } catch (error) {
    devError("[TFT] Failed to fetch live game data", error);
    return Promise.resolve();
  }
}

export default async function fetchData(parameters, searchParams) {
  const [region] = parameters;
  await Promise.all([
    fetchCompsData(parameters, searchParams),
    fetchLiveGame(region),
  ]);
}
