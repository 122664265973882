import createModel, { arbitraryKeys } from "@/__main__/data-model.mjs";
import LIVECLIENT_FIXTURE from "@/game-lol/fixtures/lol-live-client-fixture.json";

const rune = {
  displayName: String,
  id: Number,
};

const spell = {
  displayName: String,
};

const apiModelValidation = createModel({
  allPlayers: [
    {
      summonerName: String,
      championName: String,
      level: Number,
      position: String,
      runes: {
        keystone: rune,
        primaryRuneTree: rune,
        secondaryRuneTree: rune,
      },
      scores: {
        assists: Number,
        creepScore: Number,
        deaths: Number,
        kills: Number,
        wardScore: Number,
      },
      summonerSpells: {
        summonerSpellOne: spell,
        summonerSpellTwo: spell,
      },
    },
  ],
});

const afterTransformValidation = createModel({
  summonersBySummonerName: {
    [arbitraryKeys]: {
      summonerName: String,
      championName: String,
      keystone: Number,
      primaryRuneTree: Number,
      secondaryRuneTree: Number,
      spell1: String,
      spell2: String,
    },
  },
});

function transform(data) {
  const { allPlayers } = apiModelValidation(data);
  const result = {
    summonersBySummonerName: {},
  };

  for (const player of allPlayers) {
    const { summonerName, championName, runes, summonerSpells } = player;

    const cell = {
      summonerName,
      championName,
      keystone: runes.keystone.id,
      primaryRuneTree: runes.primaryRuneTree.id,
      secondaryRuneTree: runes.secondaryRuneTree.id,
      spell1: summonerSpells.summonerSpellOne.displayName,
      spell2: summonerSpells.summonerSpellTwo.displayName,
    };

    result.summonersBySummonerName[(summonerName || "")?.toLowerCase()] = cell;
  }

  return afterTransformValidation(result);
}

export function fixture() {
  return LIVECLIENT_FIXTURE;
}

export default transform;
